import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      window.location.replace("https://getluna.dev");
    } else {
      const currentUrl = window.location.href;
      const newUrl = currentUrl.split("getluna.ai").join("getluna.dev");
      window.location.replace(newUrl);
    }
  }, []);

  return <div />;
}

export default App;
